import React from "react";
import classes from "./Comment.module.scss";

const Comment = () => {
  return (
    <svg
      width="25"
      height="25"
      version="1.1"
      viewBox="0 0 650 650"
      className={classes.comment}
      style={{ fill: "blue" }}
    >
      <g>
        <path d="m567.2 43.75h-434.41c-30.125 0-54.625 24.508-54.625 54.625v257.43c0 30.125 24.508 54.625 54.625 54.625l6.3281 0.003906v51.188c0 21.961 13.039 41.684 33.207 50.234 6.8164 2.9102 14.027 4.3906 21.418 4.3906 14.156 0 27.605-5.4258 37.871-15.27l94.113-90.547h241.46c30.125 0 54.625-24.508 54.625-54.625l0.003906-257.43c0-30.117-24.5-54.625-54.625-54.625zm-434.41 334.73c-12.504 0-22.68-10.176-22.68-22.68v-257.43c0-12.504 10.176-22.68 22.68-22.68h434.4c12.504 0 22.68 10.176 22.68 22.68v257.43c0 12.504-10.176 22.68-22.68 22.68h-254.32l-103.38 99.473c-4.2695 4.0859-9.8516 6.3359-15.734 6.3359-3.0195 0-6.0898-0.62891-8.8711-1.8203-8.3828-3.5625-13.809-11.742-13.809-20.859v-83.133z" />
        <path d="m338.66 177.96h-141.21c-8.5391 0-15.461-6.9219-15.461-15.461 0-8.5391 6.9219-15.461 15.461-15.461h141.21c8.5391 0 15.461 6.9219 15.461 15.461 0 8.5391-6.9219 15.461-15.461 15.461z" />
        <path d="m420.09 243.92h-222.63c-8.5391 0-15.461-6.9219-15.461-15.461 0-8.5391 6.9219-15.461 15.461-15.461h222.63c8.5391 0 15.461 6.9219 15.461 15.461 0 8.543-6.918 15.461-15.461 15.461z" />
        <path d="m502.55 309.89h-305.09c-8.5391 0-15.461-6.9219-15.461-15.461 0-8.5391 6.9219-15.461 15.461-15.461h305.09c8.5391 0 15.461 6.9219 15.461 15.461 0 8.5391-6.9219 15.461-15.461 15.461z" />
      </g>
    </svg>
  );
};

export default Comment;
